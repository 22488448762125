import { useEffect, useState } from "react";
import { getBalance, useConnection } from "../Solana.utils";
import { PublicKey } from "@solana/web3.js";
import { Typography } from "@mui/material";

export default function SolBalance() {
  const [balance, setBalance] = useState("");
  const connection = useConnection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publicKey = new PublicKey(process.env.REACT_APP_PUBLICKEY);

  useEffect(() => {
    const checkBalance = async () => {
      const availBalance = await getBalance(connection, publicKey);
      setBalance(availBalance);
    };
    checkBalance();
  }, [connection, publicKey]);

  return (
    <Typography variant="subtitle2" fontWeight={600}>
      Avail Balance :{" "}
      <span style={{ color: "#735DA5", fontWeight: 600 }}>{balance}</span>
    </Typography>
  );
}
