import React, { useState, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "./Layout";
import withSuspense from "./WithSupense";

// Lazy load components
const AdminLogin = lazy(() => import("./Pages/Login/AdminLogin"));
const WelcomePage = lazy(() => import("./Pages/WelcomePage"));
const UploadedDetails = lazy(() => import("./Pages/Uploaded/UploadedDetails"));
const ListMovies = lazy(() => import("./Pages/ListMovies/ListMovies"));
const MarketPlace = lazy(() => import("./Pages/MarketPlace/MarketPlace"));
const Transaction = lazy(() => import("./Pages/Transaction/Transaction"));
const ListMovieDetails = lazy(() =>
  import("./Pages/ListMovies/ListMovieDetails")
);
const MarketDetail = lazy(() => import("./Pages/MarketPlace/MarketDetail"));
const AccountTab = lazy(() => import("./Pages/AccountCreation/AccountTab"));
const EnquiryForm = lazy(() => import("./Pages/Enquiry/EnquiryForm"));
const UploadsTab = lazy(() => import("./Pages/Uploaded/UploadsTab"));
const UpdateUpload = lazy(() => import("./Pages/Uploaded/UpdateUpload"));
const CMS = lazy(() => import("./Pages/CMS/CMS"));

// this variable is for local development
export const startUrl = `/`;

function Routes() {
  const [successOpen, setsuccessOpen] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [successStatus, setsuccessStatus] = useState(false);
  const [successColor, setsuccessColor] = useState(false);
  const auth = false;

  return useRoutes([
    {
      path: startUrl,
      element: withSuspense(AdminLogin)({
        setsuccessOpen,
        setsuccessMessage,
        setsuccessStatus,
        setsuccessColor,
      }),
    },
    {
      path: `${startUrl}app`,
      element: !auth ? (
        <Layout
          successOpen={successOpen}
          setsuccessOpen={setsuccessOpen}
          successMessage={successMessage}
          successStatus={successStatus}
          successColor={successColor}
        />
      ) : (
        <Navigate to={startUrl} />
      ),
      children: [
        {
          path: `${startUrl}app`,
          element: <Navigate to={`home`} />,
        },
        {
          path: "home",
          element: withSuspense(WelcomePage)(),
        },
        {
          path: "uploads",
          element: withSuspense(UploadsTab)(),
        },
        {
          path: "uploads/uploadeddetail",
          element: withSuspense(UploadedDetails)(),
        },
        {
          path: "uploads/updateuploads",
          element: withSuspense(UpdateUpload)(),
        },
        {
          path: "listing",
          element: withSuspense(ListMovies)(),
        },
        {
          path: "listing/listmoviedetail",
          element: withSuspense(ListMovieDetails)(),
        },
        {
          path: "marketplace",
          element: withSuspense(MarketPlace)(),
        },
        {
          path: "marketplace/marketdetail",
          element: withSuspense(MarketDetail)(),
        },
        {
          path: "transaction",
          element: withSuspense(Transaction)(),
        },
        {
          path: "account",
          element: withSuspense(AccountTab)(),
        },
        {
          path: "enquiry",
          element: withSuspense(EnquiryForm)(),
        },
        {
          path: "cms",
          element: withSuspense(CMS)(),
        },
        {
          path: `logout`,
          element: <Navigate to={startUrl} />,
        },
      ],
    },
  ]);
}

export default Routes;
